exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apoie-tsx": () => import("./../../../src/pages/apoie.tsx" /* webpackChunkName: "component---src-pages-apoie-tsx" */),
  "component---src-pages-depoimentos-tsx": () => import("./../../../src/pages/depoimentos.tsx" /* webpackChunkName: "component---src-pages-depoimentos-tsx" */),
  "component---src-pages-fale-conosco-tsx": () => import("./../../../src/pages/fale-conosco.tsx" /* webpackChunkName: "component---src-pages-fale-conosco-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-na-midia-tsx": () => import("./../../../src/pages/na-midia.tsx" /* webpackChunkName: "component---src-pages-na-midia-tsx" */),
  "component---src-pages-parceiros-tsx": () => import("./../../../src/pages/parceiros.tsx" /* webpackChunkName: "component---src-pages-parceiros-tsx" */),
  "component---src-pages-projetos-e-acoes-tsx": () => import("./../../../src/pages/projetos-e-acoes.tsx" /* webpackChunkName: "component---src-pages-projetos-e-acoes-tsx" */),
  "component---src-pages-quem-somos-tsx": () => import("./../../../src/pages/quem-somos.tsx" /* webpackChunkName: "component---src-pages-quem-somos-tsx" */)
}

